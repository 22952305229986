import {Component, OnInit, ViewChild} from '@angular/core';
import {CustomModalComponent, CustomTableComponent} from "@frontend/shared";
import * as dayjs from 'dayjs';
import {Subject, takeUntil} from "rxjs";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {select, Store} from "@ngrx/store";
import {
  AdminState,
  DistributeTokenList,
  getDistributeToken, getSuccess,
  UpdateDistributeTokenStatus,
} from "@frontend/admin-store";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {ToastrService} from "ngx-toastr";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'distribute-token',
  templateUrl: './distribute-token.component.html',
  styleUrls: ['./distribute-token.component.scss'],
})
export class DistributeTokenComponent implements OnInit {
  currentUser: any;
  submitted = false;
  unsubscriber = new Subject();
  filter: UntypedFormGroup;
  status: any[] = [
    {
      name: 'All',
      id: 'all'
    },
    {
      name: 'Completed',
      id: 'COMPLETED'
    },
    {
      name: 'Pending',
      id: 'PENDING'
    },
    {
      name: 'Failed',
      id: 'FAILED'
    },
  ]
  month: any[] = [
    {
      name: 'Current Week',
      id: `${dayjs().startOf('week').format('YYYY-MM-DD')}/${dayjs().format('YYYY-MM-DD')}`
    },
    {
      name: 'Current Month',
      id: `${dayjs().startOf('month').format('YYYY-MM-DD')}/${dayjs().format('YYYY-MM-DD')}`
    },
    {
      name: 'Last 30 days',
      id: `${dayjs().subtract(30, 'days').format('YYYY-MM-DD')}/${dayjs().format('YYYY-MM-DD')}`
    },
    {
      name: 'Last 6 month',
      id: `${dayjs().subtract(6, 'month').format('YYYY-MM-DD')}/${dayjs().format('YYYY-MM-DD')}`
    },
    {
      name: 'Last 12 Months',
      id: `${dayjs().subtract(12, 'month').format('YYYY-MM-DD')}/${dayjs().format('YYYY-MM-DD')}`
    },
  ];
  customDatatableSettings: any = {};
  currentPage = 1;
  itemsPerPage = 10;
  timeout: any = null;
  searchText: any = '';
  tokensData: any = []
  isUpdateAction = false;
  searchData = '';
  queryParams: any = {};
  sort: any = {};
  @ViewChild('customTableComponent', {static: true}) customTableComponent: CustomTableComponent;

  constructor(
    private adminStore: Store<AdminState>,
    private formBuilder: UntypedFormBuilder,
    private modelService: BsModalService,
    private toastr: ToastrService,
    private activeRoute: ActivatedRoute,
    private router: Router,

  ) {
    this.filter = this.formBuilder.group({
      fromDate: [null, [Validators.required]],
      toDate: [null, [Validators.required]],
      monthRange: [`${dayjs().startOf('month').format('YYYY-MM-DD')}/${dayjs().format('YYYY-MM-DD')}`, [Validators.required]],
      status: ['all', [Validators.required]]
    })
    this.subscribeToStore()

    this.activeRoute.queryParams.subscribe(query => {
      this.queryParams = {
        page: parseInt(query.page || 1),
        limit: 10,
        search: query.search || '',
        // sort: query.sort ||  {field: 'createdAt', dir: "desc"},
        monthRange: query.monthRange || `${dayjs().startOf('month').format('YYYY-MM-DD')}/${dayjs().format('YYYY-MM-DD')}`,
        status: query.status || 'all'
      };
      this.searchData = query.search || ''
      this.sort = query.sort ? JSON.parse(query.sort) : {}
      this.callApi();
    });
  }

  ngOnInit(): void {
    this.customDatatableSettings = {
      paging: true,
      withPage: true,
      serverSide: true,
      columns: [{data: null}, {data: null}, {data: null}, {data: null}, {data: null}],
      addAjax: true,
      orderColumns: ['full_name', 'tokens', 'createdAt', 'status'],
      order: [[1, 'desc']],
      searching: false,
    }
  }

  subscribeToStore() {
    this.adminStore.pipe(select(getDistributeToken))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(distributeTokens => {
        if (distributeTokens) {
          this.tokensData = distributeTokens.data;
          if (this.customTableComponent?.ajaxCallback) {
            this.customTableComponent?.ajaxCallback({
              recordsTotal: distributeTokens.count.total,
              recordsFiltered: distributeTokens.count.total,
              data: []
            })
            setTimeout(() => {
              this.customTableComponent?.reAdjustColumnsInTable();
            }, 500);
          }
        } else {
          this.tokensData = [];
        }
      })

    this.adminStore.pipe(select(getSuccess))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(success => {
        if (success) {
          if (this.isUpdateAction) {
            this.isUpdateAction = false;
            this.toastr.success(success)
            this.callApi();
          }
        }
      })
  }

  getAllWalletTransaction = (page = 0, perPage = 10, search = '', order = {
      filterBy: 'createdAt',
      filterType: 'desc'
    }) => {
    if (this.sort.field === order.filterBy) {
      this.sort = {field: order.filterBy, dir: order.filterType};
    } else {
      this.sort = {field: order.filterBy, dir: order.filterType};
    }
    this.router.navigate(['/distribute-tokens'], {
        queryParams: {
          page,
          limit: perPage,
          search,
          ...order,
          monthRange: this.filter.value.monthRange,
          status: this.filter.value.status,
          sort: JSON.stringify(this.sort)
        },
        queryParamsHandling: 'merge'
      }
    );
  }

  filterMonthRangeData(page: any = {}) {
    if (page?.page) {
      this.currentPage = page?.page;
    }
    this.router.navigate(['/distribute-tokens'], {
        queryParams: {
          ...this.queryParams,
          monthRange: this.filter.value.monthRange,
        },
        queryParamsHandling: 'merge'
      }
    );
  }

  filterStatusData(page: any = {}) {
    if (page?.page) {
      this.currentPage = page?.page;
    }
    this.router.navigate(['/distribute-tokens'], {
        queryParams: {
          ...this.queryParams,
          page:this.currentPage,
          status: this.filter.value.status
        },
        queryParamsHandling: 'merge'
      }
    );
  }

  search(event: any) {
    if (event) {
      let walletSearch = event.target.value;
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        walletSearch = walletSearch.trim();
        this.searchText = walletSearch;
        this.router.navigate(['/distribute-tokens'], {
            queryParams: {
              ...this.queryParams,
              search: walletSearch
            },
            queryParamsHandling: 'merge'
          }
        );
      }, 1500);
    }
  }

  updateStatus(event: any, item: any) {
    event.preventDefault();
    event.stopPropagation();
    const modelRef: BsModalRef = this.modelService.show(CustomModalComponent, {
      keyboard: true,
      animated: true,
      ignoreBackdropClick: false,
      initialState: {
        showImage: false
      },
      class: 'modal-md modal-dialog-centered custom-model'
    });
    modelRef.content.headerTitle = 'You are sure to transfer this tokens';
    modelRef.content.title = 'Distribute Token';
    modelRef.content.confirmText = 'Confirmed';
    modelRef.content.closeEvent.subscribe((data: any) => {
      if (data) {
        this.adminStore.dispatch(UpdateDistributeTokenStatus({
          params: {
            id: item._id,
            status: 'COMPLETED'
          }
        }))
        this.isUpdateAction = true
      }
    })
  }

  callApi() {
    console.log('this.queryParams.sort', this.queryParams.sort)
    this.adminStore.dispatch(DistributeTokenList({
      params: {
        page: this.queryParams.page,
        limit: this.queryParams.limit,
        search: this.queryParams.search,
        monthRange: this.queryParams.monthRange,
        status: this.queryParams.status,
        sort: this.queryParams.sort
      }
    }))
  }

  getCurrentUser(event: any) {
    this.currentUser = event;
  }

  redirectBack() {
    history.back();
  }
}
