<div class="">
  <div class="main_wrapper">
    <frontend-verify-header-admin (eventCurrentUser)="getCurrentUser($event)"></frontend-verify-header-admin>
    <frontend-side-bar-admin></frontend-side-bar-admin>
    <section class="main_content" >
        <div class="main_sec">
          <div class="sec_content">
            <div class="finances position-relative">
              <div class="container-fluid">
                <div class="row">
                  <div class="col-12 p-0">
                    <div class="back_btn" (click)="redirectBack()">
                      <a><img src="assets/images/back_ic.svg" />{{ 'back' | translate }}</a>
                    </div>
                    <div class="d-flex align-items-center justify-content-between mt-3">
                      <h1 class="fs-32 title"> {{'finances' | translate}}</h1>
                    </div>
                    <div class="tab-content mt-3">
                      <div class="tab-pane fade show active" id="payout" role="tabpanel" aria-labelledby="payout-tab">
                        <div class="finances-list">
                          <div class="wallet-balance">
                            <div class="container-fluid">
                              <div class="row balance-row m-0 mb-2 mb-md-0">
                                <div class="col-6 col-sm-4 px-2 text-start text-md-center mb-1">
                                  <h6 class="fs-14">{{'total_revenue' | translate}}</h6>
                                  <frontend-currency [price]="payoutTransactionDetail?.revenue || 0" tag="h2" class="fs-24 finance-price fw-bold"></frontend-currency>
                                </div>
                                <div class="col-6 col-sm-4 px-2 text-start text-md-center mb-1">
                                  <h6 class="fs-14">{{'net_revenue' | translate}}</h6>
                                  <frontend-currency [price]="payoutTransactionDetail?.netRevenue || 0" tag="h2" class="fs-24 finance-price fw-bold" ></frontend-currency>
                                </div>
                                <div class="col-6 col-sm-4 px-2 text-start text-md-center mb-1">
                                  <h6 class="fs-14">{{'artist_profit' | translate}}</h6>
                                  <frontend-currency [price]="payoutTransactionDetail?.artistProfit || 0" tag="h2" class="fs-24 finance-price fw-bold"></frontend-currency>
                                </div>
                              </div>

                              <div class="row balance-row m-0">
                                <div class="col-6 col-sm-4 px-2 text-start text-md-center mb-1">
                                  <h6 class="fs-14">{{'total_tokens' | translate}}</h6>
                                  <h2 class="fs-24 finance-price fw-bold"><img class="img-wrapper w-auto" src="assets/images/token-coin.png" />{{payoutTransactionDetail?.totalTokens || 0}}</h2>
                                </div>
                                <div class="col-6 col-sm-4 px-2 text-start text-md-center mb-1">
                                  <h6 class="fs-14">{{'admin_tokens' | translate}}</h6>
                                  <h2 class="fs-24 finance-price fw-bold"><img class="img-wrapper w-auto" src="assets/images/token-coin.png" />{{payoutTransactionDetail?.adminTokens || 0}}</h2>
                                </div>
                                <div class="col-6 col-sm-4 px-2 text-start text-md-center mb-1">
                                  <h6 class="fs-14">{{'artist_tokens' | translate}}</h6>
                                  <h2 class="fs-24 finance-price fw-bold"><img class="img-wrapper w-auto" src="assets/images/token-coin.png" />{{payoutTransactionDetail?.artistTokens || 0}}</h2>
                                  <button (click)="sendTokens()" class="btn-verify">Send Token</button>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="container-fluid">
                            <form [formGroup]="filter">
                              <div class="row my-2 my-md-4">
                                <div class="col-md-6 px-0 px-md-2 mb-1 mb-md-0">
                                  <div class="form-group">
                                    <div class="left-header d-md-block">
                                      <div class="form-group mb-0">
                                        <div class="position-relative">
                                          <img src="assets/images/search_tab.png" class="search-icon status-search" alt="" />
                                          <input type="search" class="form-control br-16 search-input"
                                                 placeholder="{{'search_name_status' | translate}}"
                                                 (keyup)="search($event)">
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div [ngClass]="selectedRoleTypeFilter === 'artist' ? 'col-md-2' : 'col-md-3'" class="px-0 px-md-2 mb-1 mb-md-0">
                                  <div class="form-group">
                                    <label class="form-label fs-12 m-0">{{'date_range' | translate}}</label>
                                    <frontend-custom-select
                                      class="filter-wallet-data"
                                      [searchable]="true"
                                      [placeholder]="('select_date' | translate)"
                                      [selectOptions]="month"
                                      selectedValue="monthRange"
                                      (optionSelected)="filterWalletData()"
                                      [formGroup]="filter">
                                    </frontend-custom-select>
                                  </div>
                                </div>
                                <div [ngClass]="selectedRoleTypeFilter === 'artist' ? 'col-md-2' : 'col-md-3'"  class="px-0 px-md-2 mb-1 mb-md-0">
                                  <div class="form-group">
                                    <label class="form-label fs-12 m-0">{{'role_type' | translate}}</label>
                                    <frontend-custom-select
                                      class="filter-wallet-data"
                                      [searchable]="true"
                                      [placeholder]="('select_type' | translate)"
                                      [selectOptions]="roleType"
                                      selectedValue="roleType"
                                      (optionSelected)="filterRoleTypeData()"
                                      [formGroup]="filter">
                                    </frontend-custom-select>
                                  </div>
                                </div>
                                <div  *ngIf="selectedRoleTypeFilter === 'artist'" [ngClass]="selectedRoleTypeFilter === 'artist' ? 'col-md-2' : 'col-md-3'" class="px-0 px-md-2 mb-1 mb-md-0">
                                  <div class="form-group">
                                    <label class="form-label fs-12 m-0">{{'status' | translate}}</label>
                                    <frontend-custom-select
                                      class="filter-wallet-data"
                                      [searchable]="true"
                                      [placeholder]="('select_type' | translate)"
                                      [selectOptions]="artistStatus"
                                      selectedValue="artistStatus"
                                      (optionSelected)="filterArtistStatusData()"
                                      [formGroup]="filter">
                                    </frontend-custom-select>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>

                          <div class="container-fluid">
                            <div class="row">
                              <div class="finance-table px-0">
                                <div class="custom-table finances-table">
                                  <frontend-custom-table
                                    #customTableComponent
                                    [customOptions]="customDatatableSettings"
                                    [tableHead]="tableHead"
                                    [ajaxFunction]="getAllWalletTransaction"
                                    [tableBody]="tableBody">
                                  </frontend-custom-table>
                                  <ng-template #tableHead>
                                    <thead>
                                    <tr>
                                      <td>
                                        <p
                                          class="title d-flex align-items-center position-relative mb-0 fs-14 fw-bold store-content-details">
                                          {{'name' | translate}}
                                        </p>
                                      </td>
                                      <td>
                                        <p
                                          class="title d-flex align-items-center position-relative mb-0 fs-14 fw-bold store-content-details">
                                          {{'transactions_type' | translate}}
                                        </p>
                                      </td>
                                      <td>
                                        <p
                                          class="title d-flex align-items-center position-relative mb-0 fs-14 fw-bold store-content-details">
                                          {{'transactions_date' | translate}}
                                        </p>
                                      </td>
                                      <td>
                                        <p
                                          class="title d-flex align-items-center position-relative mb-0 fs-14 fw-bold store-content-details">
                                          {{'tokens' | translate}}
                                        </p>
                                      </td>
                                      <td>
                                        <p
                                          class="title d-flex align-items-center position-relative mb-0 fs-14 fw-bold store-content-details">
                                          {{'amount' | translate}}
                                        </p>
                                      </td>
                                      <td>
                                        <p
                                          class="title d-flex align-items-center position-relative mb-0 fs-14 fw-bold store-content-details">
                                          {{'status' | translate}}
                                        </p>
                                      </td>
                                    </tr>
                                    </thead>
                                  </ng-template>
                                  <ng-template #tableBody>
                                    <tbody *ngIf="monthlyTransactionList.length <= 0">
                                    <tr>
                                      <td colspan="4">{{'no_data_found' | translate}}</td>
                                    </tr>
                                    </tbody>
                                    <tbody *ngIf="monthlyTransactionList.length > 0">

                                    <ng-container *ngFor="let transaction of monthlyTransactionList">
                                      <tr *ngIf="transaction.type === 'group'; else listTemplate" class="group">
                                        <td colspan="4">{{transaction.value}}</td>
                                      </tr>
                                      <ng-template #listTemplate>
                                        <tr>
                                          <td>
                                            <p class="mb-0 fs-16 store-content-details">
                                              {{selectedRoleTypeFilter === 'user' ? transaction?.user_id?.full_name : transaction?.artist_id?.full_name}}
                                            </p>
                                          </td>
                                          <td>
                                            <p class="mb-0 fs-16 store-content-details">
                                              {{transaction?.product}}
                                            </p>
                                          </td>
                                          <td>
                                            <p class="mb-0 fs-16 store-content-details">
                                              {{transaction?.createdAt | date: 'dd/MM/yyyy'}}
                                            </p>
                                          </td>
                                          <td>
                                            <p class="mb-0 fs-16 store-content-details">
                                              <img class="img-wrapper w-auto coin-icon" src="assets/images/token-coin.png" />
                                              {{transaction?.tokens || 0}}
                                            </p>
                                          </td>
                                          <td>
                                            <p class="mb-0 fs-16 store-content-details">
                                              ${{transaction?.amount?.toFixed(2) || 0}}
                                            </p>
<!--                                            <kwot-currency [price]="transaction" tag="p" type="wallet" -->
<!--                                                           class="mb-0 fs-16 store-content-details" field="amount"-->
<!--                                                           ></kwot-currency>-->
<!--                                            <kwot-currency [price]="transaction?.amount || 0"-->
<!--                                                           [item]="transaction.vendorId" tag="p" type="wallet" [hasField]="false"-->
<!--                                                           class="mb-0 fs-16 store-content-details" field="amount"-->
<!--                                                           [platformFee]="false"></kwot-currency>-->
                                          </td>
                                          <td *ngIf="transaction.status == 'ON HOLD'">
                                            <frontend-custom-select
                                              class="status-data"
                                              [searchable]="true"
                                              [isDisabled]="!checkData()"
                                              placeholder="{{ 'select_status' | translate }}"
                                              (optionSelected)="statusData(transaction._id)"
                                              [selectOptions]="status"
                                              [(selectedValue)]="transaction.status"
                                            >
                                            </frontend-custom-select>
                                          </td>
                                          <td *ngIf=" transaction.status !== 'ON HOLD'">
                                            <p class="mb-0 fs-16 store-content-details text-uppercase">
                                              {{selectedRoleTypeFilter === 'user' ? transaction?.token_type : transaction.status}}
                                            </p>
                                          </td>
                                        </tr>
                                      </ng-template>
                                    </ng-container>
                                    </tbody>
                                  </ng-template>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
  </div>
</div>
