import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { VerifyProfileComponent } from './components/verify-profile/verify-profile.component';
import { VerifyPendingComponent } from './components/verify-pending/verify-pending.component';
import { NoAuthGuard } from './guards/no-auth.guard';
import { AuthGuard } from './guards/auth.guard';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@frontend/shared';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import {
  APP_CONFIG,
  HttpAuthInterceptService,
  ToastComponent,
} from '@frontend/app-config';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BsModalService } from 'ngx-bootstrap/modal';
import { environment } from '../environments/environment';
import { CommonComponentModule } from './modules/common-component/common-component.module';
import { AdminStoreModule } from '@frontend/admin-store';
import { RadioStationComponent } from './modules/radio-stations/components/radio-station.component';
import { FinanceComponent } from './modules/finance/finance.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {ListenersComponent} from "./modules/listeners/listeners.component";
import {PaginationModule} from "ngx-bootstrap/pagination";
import {PopoverModule} from "ngx-bootstrap/popover";
import {
  FinanceSendTokenArtistModalComponent
} from "./modules/finance/finance-send-token-artist-modal/finance-send-token-artist-modal.component";
import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthServiceConfig } from '@abacritt/angularx-social-login';
import { ArtistTokensComponent } from './modules/artist-tokens/components/artist-tokens.component';
import { DistributeTokenComponent } from './modules/distribute-token/distribute-token.component';

const routes: Routes = [
  { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
  {
    path: '',
    loadChildren: () =>
      import('@frontend/auth').then((module) => module.AuthModule),
    canActivate: [NoAuthGuard],
  },
  {
    path: 'artists',
    loadChildren: () =>
      import('./modules/artists/artists.module').then(
        (module) => module.ArtistsModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'listeners',
    component: ListenersComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'music',
    loadChildren: () =>
      import('./modules/musics/musics.module').then(
        (module) => module.MusicsModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'podcast',
    loadChildren: () =>
      import('./modules/podcast/podcast.module').then(
        (module) => module.PodcastModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'skits',
    loadChildren: () =>
      import('./modules/skits/skits.module').then(
        (module) => module.SkitsModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'radio',
    loadChildren: () =>
      import('./modules/radio-stations/radio-stations.module').then(
        (module) => module.RadioStationsModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'fan-club-plans',
    loadChildren: () =>
      import('./modules/fan-club-plans/fan-club-plans.module').then(
        (module) => module.FanClubPlansModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'token',
    loadChildren: () =>
      import('./modules/tokens/token-plans.module').then(
        (module) => module.TokenPlansModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'token-cost',
    loadChildren: () =>
      import('./modules/token-cost/token-cost.module').then(
        (module) => module.TokenCostModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'commission',
    loadChildren: () =>
      import('./modules/platform-commission/platform-commissions.module').then(
        (module) => module.PlatformCommissionsModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'Subscription',
    loadChildren: () =>
      import('./modules/Subscription/Subscription-plans.module').then(
        (module) => module.SubscriptionPlansModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'category',
    loadChildren: () =>
      import('./modules/categories/categories.module').then(
        (module) => module.CategoriesModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'ads',
    loadChildren: () =>
      import('./modules/ads/ads.module').then((module) => module.AdsModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'feature',
    loadChildren: () =>
      import('./modules/features/features.module').then((module) => module.FeaturesModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'staff',
    loadChildren: () => import('./modules/staff/staff.module').then((module) => module.StaffModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'finance',
    component: FinanceComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'distribute-tokens',
    component: DistributeTokenComponent,
    canActivate: [AuthGuard],
  },
  // {
  //   path: 'inbox',
  //   component: FinanceComponent,
  //   canActivate: [AuthGuard],
  // },
  {
    path: 'promo-code',
    loadChildren: () =>
      import('@frontend/promo-code').then((module) => module.PromoCodeModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'artist-tokens',
    loadChildren: () =>
      import('./modules/artist-tokens/artist-tokens.module').then((module) => module.ArtistTokensModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard],
  },
  { path: 'verify-profile', component: VerifyProfileComponent },
  { path: 'verify-pending', component: VerifyPendingComponent },
  // { path: 'category', component: CategoryComponent },
];

const fbLoginOptions = {
  scope: 'email, public_profile',
  return_scopes: true,
  enable_profile_selector: true
};

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    VerifyProfileComponent,
    VerifyPendingComponent,
    // CategoryComponent,
    ToastComponent,
    RadioStationComponent,
    FinanceComponent,
    ListenersComponent,
    FinanceSendTokenArtistModalComponent,
    ArtistTokensComponent,
    DistributeTokenComponent
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(routes, {initialNavigation: 'enabledBlocking'}),
    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),
    CommonModule,
    SharedModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      closeButton: true,
      maxOpened: 2,
      autoDismiss: true,
      preventDuplicates: true,
      positionClass: 'toast-top-full-width',
      toastComponent: ToastComponent,
      toastClass: 'ngx-toastr custom-toast',
    }),
    HttpClientModule,
    CommonComponentModule,
    AdminStoreModule,
    CommonComponentModule,
    ReactiveFormsModule,
    FormsModule,
    PaginationModule,
    PopoverModule
  ],
  providers: [
    { provide: APP_CONFIG, useValue: environment },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpAuthInterceptService,
      multi: true,
    },
    BsModalService,
      {
        provide: 'SocialAuthServiceConfig',
        useValue: {
          autoLogin: false,
          lang: 'en',
          providers: [
            {
              id: GoogleLoginProvider.PROVIDER_ID,
              provider: new GoogleLoginProvider(
                '312258426675-udgm6rbcc3ap9apihu6svnug22ljsr7d.apps.googleusercontent.com'
              )
            },
            {
              id: FacebookLoginProvider.PROVIDER_ID,
              provider: new FacebookLoginProvider('1729180150888493', fbLoginOptions)
            }
          ],
          onError: (err) => {
            console.error(err);
          }
        } as SocialAuthServiceConfig,
      }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
