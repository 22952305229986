import {Inject, Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {createEffect, Actions, ofType} from '@ngrx/effects';
import {catchError, map, of, switchMap} from 'rxjs';
import {APP_CONFIG, LoaderService} from '@frontend/app-config';
import {AuthService} from '../services/auth.service';
import {
  Login,
  LoginError,
  LoginSuccess,
  Register,
  RegisterError,
  RegisterSuccess,
  SendOtp,
  SendOtpError,
  SendOtpSuccess,
  ValidateOtp,
  ValidateOtpError,
  ValidateOtpSuccess,
  ForgotPassword,
  ForgotPasswordError,
  ForgotPasswordSuccess,
  ResetPassword,
  ResetPasswordSuccess,
  ResetPasswordError,
  CheckAccountVerifyLink,
  CheckAccountVerifyLinkError,
  CheckAccountVerifyLinkSuccess,
  VerifyData,
  VerifyDataError,
  VerifyDataSuccess,
  PurchaseToken,
  PurchaseTokenSuccess,
  PurchaseTokenError,
  LoginWithToken,
  SignInWithSocial,
  SignInWithSocialSuccess,
  PaymentIntent,
  PaymentIntentSuccess,
  PaymentIntentError
} from './auth.actions';
import {PaymentGatwayService} from "../../../../payment-gatways/src/lib/services/payment-gatway.service";
import { SocialAuthService } from '@abacritt/angularx-social-login';
import { SocialUser } from '@frontend/data-models';

@Injectable()
export class AuthEffects {
  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private paymentGatewayService: PaymentGatwayService,
    private authSocialService: SocialAuthService,
    private loaderService: LoaderService,
    // private localStorageService: LocalstorageService,
    private router: Router,
    @Inject(APP_CONFIG) public appConfig: any
  ) {
  }

  register$ = createEffect(() =>
    this.actions$.pipe(
      ofType(Register),
      switchMap((action) => {
        this.loaderService.show();
        return this.authService.register(action.user).pipe(
          map((resp: any) => {
            this.loaderService.hide();
            if (resp.success) {
              // const user = {
              //   ...resp.data,
              //   id: resp.data._id,
              // };
              if (resp.data.email) {
                this.router.navigate(['/sign-in']);
              }
              return RegisterSuccess({
                registerUser: resp.data,
                message: resp.message,
              });
            }
            return RegisterError({error: this.loaderService.getErrorMessage(resp)});
          }),
          catchError((error) => {
            return of(RegisterError({error: this.loaderService.getErrorMessage(error)}));
          })
        );
      })
    )
  );

  validateOtp$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ValidateOtp),
      switchMap((action) => {
        this.loaderService.show();
        return this.authService.validateOtp(action.otp).pipe(
          map((resp: any) => {
            this.loaderService.hide();
            if (resp.success) {
              return ValidateOtpSuccess({success: resp});
            }

            return ValidateOtpError({
              error: this.loaderService.getErrorMessage(resp),
            });
          }),
          catchError((error) => {
            this.loaderService.hide();
            return of(
              ValidateOtpError({
                error: this.loaderService.getErrorMessage(error),
              })
            );
          })
        );
      })
    )
  );

  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(Login),
      switchMap((action) => {
        this.loaderService.show();
        return this.authService.login(action.user).pipe(
          map((resp: any) => {
            this.loaderService.hide();
            if (resp.success) {
              // const user = {
              //   ...resp.data,
              //   id: resp.data._id,
              // };
              this.router.navigate(['/dashboard']);
              return LoginSuccess({
                loginUser: resp.data,
                message: resp.message,
              });
            }
            return LoginError({error: this.loaderService.getErrorMessage(resp)});
          }),
          catchError((error) => {
            this.loaderService.hide();
            return of(LoginError({error: this.loaderService.getErrorMessage(error)}));
          })
        );
      })
    )
  );

  sendOtp$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SendOtp),
      switchMap((action) => {
        this.loaderService.show();
        return this.authService.sendOtp(action.phone, action.countryCode).pipe(
          map((resp: any) => {
            this.loaderService.hide();
            if (resp.status) {
              return SendOtpSuccess({success: resp.message}); // Otp Sent
            }
            return SendOtpError({
              error: this.loaderService.getErrorMessage(resp),
            });
          }),
          catchError((error) => {
            this.loaderService.hide();
            return of(SendOtpError({error: this.loaderService.getErrorMessage(error)}));
          })
        );
      })
    )
  );

  forgotPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ForgotPassword),
      switchMap((action) => {
        this.loaderService.show();
        return this.authService.forgotPassword(action.user).pipe(
          map((resp: any) => {
            this.loaderService.hide();
            if (resp.success) {
              return ForgotPasswordSuccess({success: resp.message});
            }
            return ForgotPasswordError({
              error: this.loaderService.getErrorMessage(resp),
            });
          }),
          catchError((error) => {
            this.loaderService.hide();
            return of(
              ForgotPasswordError({
                error: this.loaderService.getErrorMessage(error),
              })
            );
          })
        );
      })
    )
  );

  resetPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ResetPassword),
      switchMap((action) => {
        this.loaderService.show();
        return this.authService.resetPassword(action.passwordBody).pipe(
          map((resp: any) => {
            this.loaderService.hide();
            if (resp.success) {
              return ResetPasswordSuccess({success: resp.message}); // You have successfully changed your password.
            }
            return ResetPasswordError({
              error: this.loaderService.getErrorMessage(resp),
            });
          }),
          catchError((error) => {
            this.loaderService.hide();
            return of(
              ResetPasswordError({
                error: this.loaderService.getErrorMessage(error),
              })
            );
          })
        );
      })
    )
  );

  checkAccountVerifyLink$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CheckAccountVerifyLink),
      switchMap((action) => {
        this.loaderService.show();
        return this.authService.checkAccountVerifyLink(action.id).pipe(
          map((resp: any) => {
            this.loaderService.hide();
            if (resp.success) {
              // this.localStorageService.updateUserKey('store', resp.data);
              return CheckAccountVerifyLinkSuccess({success: resp.message});
            }
            if (resp.status) {
              return CheckAccountVerifyLinkSuccess({success: resp.data});
            }
            // if (resp.status) {
            //   return CheckAccountVerifyLinkSuccess({user: resp.data});
            // }
            return CheckAccountVerifyLinkError({
              error: this.loaderService.getErrorMessage(resp),
            });
          }),
          catchError((error) => {
            this.loaderService.hide();
            return of(
              CheckAccountVerifyLinkError({
                error: this.loaderService.getErrorMessage(error),
              })
            );
          })
        );
      })
    )
  );

  verifyData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VerifyData),
      switchMap((action) => {
        this.loaderService.show();
        return this.authService.verifyData(action.params).pipe(
          map((resp: any) => {
            this.loaderService.hide();
            if (resp.success) {
              return VerifyDataSuccess({
                success: 'Verification link has been sent to you email.',
              });
            }
            return VerifyDataError({
              error: this.loaderService.getErrorMessage(resp),
            });
          }),
          catchError((error) => {
            this.loaderService.hide();
            return of(
              VerifyDataError({
                error: this.loaderService.getErrorMessage(error),
              })
            );
          })
        );
      })
    )
  );

  purchaseToken$ = createEffect(() => this.actions$.pipe(
    ofType(PurchaseToken),
    switchMap((action) => {
      this.loaderService.show();
      return this.paymentGatewayService.paymentToken(action.body).pipe(
        map((resp: any) => {
          this.loaderService.hide();
          if (resp.success) {
            return PurchaseTokenSuccess();
          }
          return PurchaseTokenError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide();
          return of(PurchaseTokenError({error: this.loaderService.getErrorMessage(error)}));
        })
      )
    })
  ))

  loginWithToken$ = createEffect(() => this.actions$.pipe(
    ofType(LoginWithToken),
    switchMap((action) => {
      this.loaderService.show();
      return this.authService.loginWithToken(action.token).pipe(
        map((resp: any) => {
          this.loaderService.hide();
          if (resp.success) {
            const user = {
              ...resp.data,
              id: resp.data._id
            };
            return LoginSuccess({loginUser: user, message: resp.message});
          }
          return LoginError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide();
          return of(LoginError({error: this.loaderService.getErrorMessage(error)}));
        })
      )
    })
  ));

  signInWithSocial$ = createEffect(() => this.actions$.pipe(
    ofType(SignInWithSocial),
    switchMap((action) => {
      console.log('action.providerString',action.providerString)
      if (action.providerString !== 'GOOGLE') {
        this.authSocialService.signIn(action.providerString).catch((error => console.log('--------------===', error)));
      }
      return this.authSocialService.authState.pipe(
        map((resp: SocialUser) => {
          this.loaderService.hide();
          if (resp) {
            return SignInWithSocialSuccess({signInUserSocial: resp});
          }
          if (action.actionType === 'register') {
            return RegisterError({error: this.loaderService.getErrorMessage(resp || {msg: 'Social Error'})});
          } else {
            return LoginError({error: this.loaderService.getErrorMessage(resp || {msg: 'Social Error'})});
          }
        }),
        catchError(error => {
          this.loaderService.hide();
          if (action.actionType === 'register') {
            return of(RegisterError({error: this.loaderService.getErrorMessage(error)}));
          } else {
            return of(LoginError({error: this.loaderService.getErrorMessage(error)}));
          }
        })
      )
    })
  ));

  paymentIntent$ = createEffect(() => this.actions$.pipe(
    ofType(PaymentIntent),
    switchMap((action) => {
      this.loaderService.show();
      return this.paymentGatewayService.paymentIntent(action.body).pipe(
        map((resp: any) => {
          this.loaderService.hide();
          if (resp.success) {
            return PaymentIntentSuccess();
          }
          return PaymentIntentError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide();
          return of(PaymentIntentError({error: this.loaderService.getErrorMessage(error)}));
        })
      )
    })
  ))
}
